export const environment = {
    production: true,
    API_URL: 'https://admin.paco.app/api',
    ONE_SIGNAL_APP_ID: '5537a60e-aa13-4b72-96cb-1e52202f2a62',
    FIREBASE_SENDER_ID: 'FIREBASE_SENDER_ID',
    MAPSKEY: 'pk.eyJ1IjoiamN2aXpjYWlubyIsImEiOiJja2E4Zml1NHowZTkzMnJwaWdiMHlsMjZtIn0.6ciM4BUEDiwrrJi6xqlnPg',
    SMARTLOOK_KEY: '4a0168938f55324aa5d5d67e7ad826f6066894f7',
    HELP_URL:'https://ayuda.almaapp.mx/portal/es/home',
    API_KEY_ZOHO_ANDROID:'NDX2fFtcVzvndmyJF1zTzCvoLWwVtDjttPNesksqsBYKOEPg5TlIJdT4M8FFqIcW',
    API_KEY_ZOHO_IOS:"NDX2fFtcVzvndmyJF1zTzCvoLWwVtDjttPNesksqsBYKOEPg5TlIJdT4M8FFqIcW",
    ACCESS_TOKEN_ZOHO_ANDROID:'harFESiTd11MM626OJ7RWbXtiasWHEUm5Z2s5z0wAmPJklxoUSO3RI%2BCOC228Gd%2FyUPzaMsxR5fdg%2BNigIdupH2xgH%2Bi6SrYC89ocJ5max6k96GhtGKO8g%3D%3D',
    ACCESS_TOKEN_ZOHO_IOS:'Wt5HosGA%2FZlDqvBdPPSgPrrim7Vmwyl%2F2brDjBtA4xiBPX2FJj7jrWfEYufa%2Fs65B3mv8Ji%2BouNAESaR52bbicD0l3bNfRc7T1O2qhQUeqo%3D',
    XML_BASE_URL:'https://admin.paco.app',
    BUGSNAG_API_KEY:'8bbb698c6e080ffebf89835df2d45f31',
    APP_ID_IOS:'1664201415',
    APP_GENERAL_NAME:'Alma'
};
